import React from "react";

export default class SlideShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0,
    };
  }

  componentDidMount() {
    this.nextSlideInterval = setInterval(
      this.nextSlide,
      this.props.slideInterval
    );
  }

  componentWillUnmount() {
    clearInterval(this.nextSlideInterval);
  }

  nextSlide = () => {
    this.setState({
      activeSlide:
        this.state.activeSlide + 1 > this.props.images.length - 1
          ? 0
          : this.state.activeSlide + 1,
    });
  };

  previousSlide = () => {
    this.setState({
      activeSlide:
        this.state.activeSlide - 1 < 0
          ? this.props.images.length - 1
          : this.state.activeSlide - 1,
    });
  };

  render() {
    console.log(this.props);

    return (
      <div className="slideShow">
        {this.props.images &&
          this.props.images.map((el, i) => {
            console.log(el, i);
            console.log(this.state.activeSlide);

            if (this.state.activeSlide === i) {
              return (
                <img
                  key={el.ID}
                  alt="slide show"
                  className="slideImg"
                  src={el.url}
                />
              );
            } else {
              return null;
            }
          })}
      </div>
    );
  }
}
