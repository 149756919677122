import React from "react";
import ReactMarkdown from "react-markdown";

import SlideShow from "./SlideShow";

// Theme uri
// const THEME_URI = "https://"+window.location.host+'/wp-content/themes/wps-theme/';

const AJAX_URL = "https://app.findfulmap.com/wp-admin/admin-ajax.php";

// Min and max zoom for map
const MINZOOM = 1,
  MAXZOOM = 4;
const ZOOM_SENSITIVITY = 5;

// Marker width and height needs to correspond with the width and height set in main.scss
const MARKER_WIDTH = 20,
  MARKER_HEIGHT = 30;
const MARKER_HALF_WIDTH = 12.5;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      map: null,
      selectedMarkerIndex: -1,
      mapZoom: 1,
      loading: true,

      mapRequested: false,

      allCategories: [],
      activeCategory: -1,
    };

    this.mapRef = React.createRef();
    this.mapContainerRef = React.createRef();

    // #region Bind Functions ************************************************
    this.handleResize = this.handleResize.bind(this);
    this.updateMarkerPositions = this.updateMarkerPositions.bind(this);
    this.informationRetrieved = this.informationRetrieved.bind(this);
    this.mapLoaded = this.mapLoaded.bind(this);
    this.retrieveInformation = this.retrieveInformation.bind(this);
    this.mapClicked = this.mapClicked.bind(this);
    this.selectMarker = this.selectMarker.bind(this);
    this.unselectMarker = this.unselectMarker.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomReset = this.handleZoomReset.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleSidePOIEnter = this.handleSidePOIEnter.bind(this);
    this.handleSidePOILeave = this.handleSidePOILeave.bind(this);
    this.panToPos = this.panToPos.bind(this);
    this.renderMarkers = this.renderMarkers.bind(this);
    // #endregion ************************************************************
  }

  // Initialize component after mount
  componentDidMount() {
    if (!this.state.loading) {
      this.instance = window.renderer({
        scaleSensitivity: ZOOM_SENSITIVITY,
        minScale: MINZOOM,
        maxScale: MAXZOOM,
        element: this.mapContainerRef.current.children[0],
      });
      this.mapContainerRef.current.addEventListener("click", this.mapClicked);
    } else this.instance = null;

    window.onresize = this.handleResize;

    window.addEventListener("beforeunload", this.handleBeforeUnload);

    this.retrieveInformation();
  }

  // Component unmounting therefor remove event listeners etc.
  componentWillUnmount() {
    this.mapRef.current.removeEventListener("load", this.mapLoaded);
  }

  // State of component updated
  componentDidUpdate() {
    if (this.state.map === null) return;

    if (!this.instance) {
      this.instance = window.renderer({
        scaleSensitivity: ZOOM_SENSITIVITY,
        minScale: MINZOOM,
        maxScale: MAXZOOM,
        element: this.mapContainerRef.current.children[0],
      });
      this.mapContainerRef.current.addEventListener("click", this.mapClicked);
    }
  }

  // Retrieve information from database
  retrieveInformation() {
    let me = this;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mapID = urlParams.get("mapid");

    window.jQuery.ajax({
      type: "post",
      dataType: "json",
      url: AJAX_URL,
      data: {
        action: "getMap",
        mapID: mapID,
      },
      success: function (response) {
        console.log("getMap response ", response);

        me.setState(
          {
            map: response,
            loading: false,
          },
          me.informationRetrieved
        );
      },
      error: function (error) {
        console.error(error);
      },
    });
  }

  // Information has been retrieved from DB
  informationRetrieved() {
    let allCategories = [];

    console.log(this.state.map);

    // create array with all marker categories
    this.state.map.markers.forEach((el) => {
      if (el.categories) {
        let cats = el.categories;
        if (cats.length) {
          cats.forEach((c) => {
            if (allCategories.indexOf(c) === -1) allCategories.push(c);
            //console.log(c);
          });
        }
      }
    });
    allCategories = allCategories.sort();
    this.setState({ allCategories });

    if (this.mapRef.current.complete) {
      this.mapLoaded();
    } else {
      this.mapRef.current.addEventListener("load", this.mapLoaded);
    }
  }

  // Handle window resize
  handleResize() {
    // TODO:
    // Only call updateMarkerPositions on an interval
    //
    // interval(200ms) check if window has resized if yes
    // call this.updateMarkerPositions
    this.updateMarkerPositions();
  }

  // Called on map load
  mapLoaded() {
    this.updateMarkerPositions();
  }

  // Update marker positions on screen to be accurate on map coords
  updateMarkerPositions() {
    if (this.state.map === null) return;

    let map = this.state.map;
    let markers = map.markers;

    let mapEl = this.mapRef.current;

    let mapWidth = mapEl.scrollWidth;
    let mapHeight = mapEl.scrollHeight;

    for (var i = 0; i < markers.length; ++i) {
      let xPerc = markers[i].deltaPosition.xPerc;
      let yPerc = markers[i].deltaPosition.yPerc;
      markers[i].deltaPosition.x = (mapWidth * xPerc) / 100 - MARKER_HALF_WIDTH;
      markers[i].deltaPosition.y = (mapHeight * yPerc) / 100 - MARKER_HEIGHT;
    }

    map.markers = markers;

    this.setState({
      map,
    });
  }

  // The map was clicked
  mapClicked(e) {
    //console.log(e, e.target);
    //console.dir(e.target);

    if (e.target.className === "map") {
      // If the user is intending to add a marker to the map then place one at click position
      if (this.state.isAddingMarker) {
        let targetParent = e.target.parentElement;
        //console.dir(targetParent);
        let style = window.getComputedStyle(targetParent);
        let matrix = new DOMMatrix(style.webkitTransform);
        //console.log("matrix: ", matrix);

        let x = e.x - 250 - matrix.m41 - MARKER_WIDTH / 2;
        let y = e.y - 90 - matrix.m42 - MARKER_HEIGHT;

        let xPerc =
          ((x + MARKER_HALF_WIDTH) / this.mapRef.current.clientWidth) * 100;
        let yPerc =
          ((y + MARKER_HEIGHT) / this.mapRef.current.clientHeight) * 100;

        let pos = {
          x,
          y,
          xPerc,
          yPerc,
        };

        //console.log("adding marker at ",pos);

        this.addMarker(pos);
      }
    }
  }

  // Select marker at index
  selectMarker(i) {
    this.setState({
      selectedMarkerIndex: i,
    });
  }

  // Unselect current marker
  unselectMarker() {
    this.setState({
      selectedMarkerIndex: -1,
    });
  }

  // Handle zoom in button being pressed
  handleZoomIn() {
    let deltaScale = 1;
    this.instance.zoom({
      deltaScale,
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    });
    this.setState({
      mapZoom: Math.min(
        Math.max(
          this.state.mapZoom +
            deltaScale / (ZOOM_SENSITIVITY / this.state.mapZoom),
          MINZOOM
        ),
        MAXZOOM
      ),
    });
  }

  // Handle zoom reset to 0
  handleZoomReset() {
    this.instance.panTo({
      originY: 0,
      originX: 0,
      scale: 1,
    });
    this.setState({ mapZoom: 1 });
  }

  // Handle zoom out button being pressed
  handleZoomOut() {
    let deltaScale = -1;
    this.instance.zoom({
      deltaScale,
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    });
    this.setState({
      mapZoom: Math.min(
        Math.max(
          this.state.mapZoom +
            deltaScale / (ZOOM_SENSITIVITY / this.state.mapZoom),
          MINZOOM
        ),
        MAXZOOM
      ),
    });
  }

  // Called when mouse is moving over top of map
  handleMouseMove(event) {
    // Only move map if user is holding down left mouse and is not dragging a marker
    if (event.buttons !== 1 || this.state.activeDrags > 0) {
      return;
    }
    event.preventDefault();
    this.instance.panBy({
      originX: event.movementX,
      originY: event.movementY,
    });
  }

  // When mouse goes over a marker on the sidebar
  handleSidePOIEnter(e) {
    let map = this.state.map;
    let markers = map.markers;

    markers[e].highlight = true;

    map.markers = markers;

    this.setState({ map });
  }

  // When mouse leaves a marker on the sidebar
  handleSidePOILeave(e) {
    let map = this.state.map;
    let markers = map.markers;

    markers[e].highlight = false;

    map.markers = markers;

    this.setState({ map });
  }

  // IMPORTANT!!!! UNFUNCTIONAL DO NOT USE
  panToPos(x, y) {
    // UNFUNCTIONAL
    console.log(`panToPos(${x}, ${y})`);

    let zoom = 1;
    let mapHeight = 672;
    let mapWidth = 1653;

    // x, y: 931, 446
    // target: -628, -246
    // transformOrigin: -22, 172
    // mapSize: 672, 1653

    let transformOrigin =
      this.mapContainerRef.current.children[0].style.transformOrigin;

    console.log(
      transformOrigin,
      transformOrigin.split("px")[0],
      transformOrigin.split("px")[1]
    );

    this.instance.panTo({
      originY: -(
        mapHeight * zoom -
        (mapHeight * zoom - y * zoom) -
        mapHeight * 0.75 -
        120
      ),
      originX: -(
        mapWidth * zoom -
        (mapWidth * zoom - x * zoom) -
        mapWidth * 1 +
        12.5
      ),
      scale: zoom,
    });
    this.setState({ mapZoom: zoom });
  }

  // Returns list of <Draggable /> markers to be rendered on top of map based on map
  renderMarkers(map) {
    let list = [];

    let markers = map.markers ? map.markers : [];

    markers.forEach((e, i) => {
      if (e.deleted) return "";

      list.push(
        <div
          style={{
            transform:
              `translateX(${e.deltaPosition.x}px) translateY(${e.deltaPosition.y}px)` +
              (this.state.selectedMarkerIndex === i ? " scale(1.33)" : ""),
          }}
          key={i}
          className={
            "marker" +
            (e.isFeatured ? " featured" : "") +
            (e.highlight ? " highlight" : "") +
            (this.state.selectedMarkerIndex === i ? " selected" : "")
          }
          title={e.title}
          onClick={() => this.selectMarker(i)}
        >
          <div className="tooltip">{e.title}</div>
        </div>
      );
    });

    return list;
  }

  getCategorySections = () => {
    let me = this;

    let categorySections = [];
    this.state.allCategories.forEach((category) => {
      let sectionContent = [];

      me.state.map.markers.forEach((el, markerIndex) => {
        let cats = el.categories;
        if (cats.length) {
          cats.forEach((c) => {
            if (category === c)
              sectionContent.push(
                <div onClick={() => this.selectMarker(markerIndex)}>
                  {el.title}
                </div>
              );
          });
        }
      });

      categorySections.push({
        title: category === "" ? "Uncategorized" : category,
        content: sectionContent,
      });
    });
    return categorySections;
  };

  renderCategories = () => {
    let categorySections = this.getCategorySections();

    return (
      <div className="accordion">
        {categorySections.map((el, index) => {
          return (
            <div
              onClick={() => {
                this.setState({ activeCategory: index });
              }}
              className="accordion-item"
              style={
                index === this.state.activeCategory
                  ? { height: el.content.length * 40 + 40 + "px" }
                  : {}
              }
            >
              <div className="title">{el.title}</div>
              <div className="content">{el.content}</div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    if (this.state.loading) return <div>Loading...</div>;

    let map = this.state.map;
    let markers = map.markers;
    let selectedMarker =
      this.state.selectedMarkerIndex >= 0
        ? markers[this.state.selectedMarkerIndex]
        : null;

    return (
      <section className="content">
        <aside className="sidebar">
          {this.renderCategories()}
          {/*markers.map((e, i)=>{
            if(e.deleted) return;

            return (
              <a
                key={i}
                onMouseEnter={() => this.handleSidePOIEnter(i)}
                onMouseLeave={() => this.handleSidePOILeave(i)}
                onClick={() => {this.selectMarker(i); this.showSidebar(true);}}
                >
                <div className="poiTitle">{e.title}</div>
              </a>
            );
          })*/}
        </aside>
        <div
          style={{
            display: "flex",
            flex: 1,
            maxHeight: "100vh",
            position: "relative",
          }}
        >
          <div
            id="findful-map"
            className="findfulMap mapContainer"
            ref={this.mapContainerRef}
            onMouseMove={this.handleMouseMove}
          >
            <div className="mapContainerInner">
              {this.renderMarkers(map)}

              <img
                alt="map"
                ref={this.mapRef}
                draggable={false}
                src={map.image}
                className="map"
                style={
                  this.state.isAddingMarker ? { pointerEvents: "auto" } : {}
                }
              />
            </div>
            <div className="mapControls">
              <button className="btn-fill" onClick={this.handleZoomIn}>
                +
              </button>
              <button className="btn-fill" onClick={this.handleZoomOut}>
                -
              </button>
              <button className="btn-fill" onClick={this.handleZoomReset}>
                Reset Zoom
              </button>
            </div>
          </div>
          {selectedMarker && (
            <div className="cta" style={{}}>
              <div
                className="close"
                onClick={() => {
                  this.setState({ selectedMarkerIndex: -1 });
                }}
              ></div>
              <div className="ctaInner">
                <div className="ctaHero">
                  {selectedMarker.wistiaID ? (
                    <div className="video-container wistia-container">
                      <iframe
                        title="wistia-embed"
                        frameborder="0"
                        className="wistia-embed"
                        name="wistia-embed"
                        src={
                          "https://fast.wistia.com/embed/iframe/" +
                          selectedMarker.wistiaID
                        }
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  ) : selectedMarker.videoEmbedURL ? (
                    <div className="video-container youtube-container">
                      <iframe
                        title="youtube-embed"
                        frameborder="0"
                        className="youtube-embed"
                        name="youtube-embed"
                        src={
                          selectedMarker.videoEmbedURL.split("?")[0] +
                          "?rel=0&autoplay=0&showinfo=0"
                        }
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  ) : (
                    <SlideShow
                      images={selectedMarker.images}
                      slideInterval={4000}
                    />
                  )}
                </div>

                <span className="buttonText" style={{ fontWeight: "bold" }}>
                  {selectedMarker.title}
                </span>
                <div
                  className="ctaDescription"
                  style={{
                    maxHeight: "calc(100vh - 364px)",
                  }}
                >
                  <ReactMarkdown>{selectedMarker.desc}</ReactMarkdown>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default App;
